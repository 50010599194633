

//国标摄像头
var sipMediaServerIp = "47.104.165.60";
var sipMediaServerPort = 8075;

//页面数据刷新时间
//重新获取设备列表
var deviceRefreshTiming = 60 * 60 * 1000; //60 * 1000;
//设备列表轮训 - 常量
var deviceShowTimingConst = 40 * 1000;
//设备列表轮训
var deviceShowTiming = 40 * 1000; //60 * 1000;
//设备子设备轮训
var deviceChildShowTiming = 20 * 1000;
//传感器数据轮训--液体传感器
var deviceChildSensorShowTiming = 4 * 1000;
var webcamRefreshTiming = 24 * 60 * 1000
var webcamReplayerTiming = 3 * 1000;
var wasmLoadDelayTiming = 2 * 1000;

export default {
    //国标摄像头
    sipMediaServerIp,
    sipMediaServerPort,

    //页面数据刷新时间
    deviceRefreshTiming,
    deviceShowTimingConst,
    deviceShowTiming,
    deviceChildShowTiming,
    deviceChildSensorShowTiming,
    webcamRefreshTiming,
    webcamReplayerTiming,
    wasmLoadDelayTiming,
}
